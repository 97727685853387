body {
    background: #f0f2f5 !important;
}
.w-600 {
    width: 600px;
    margin: 0 auto;
}
.w-900 {
    width: 900px;
    margin: 0 auto;
}
.w-1200 {
    width: 1200px;
    margin: 0 auto;
}

//@media screen and (min-width: 1201px) {
//  .w-1200 {
//    width: 1200px;
//  }
//}

.w-1200-header {
    //width: 1200px;
    margin: 0 auto;
}

@media screen and (min-width: 1201px) {
    .w-1200-header {
        width: 1200px;
    }
}

.statistic-fee {
    text-align: center;
    .ant-statistic-content {
        color: @primary-color;
        font-size: 14px !important;
        line-height: 14px !important;
    }
    .ant-statistic-content-value-int {
        font-weight: bold;
        font-size: 18px !important;
        line-height: 18px !important;
    }
}

.home-list {
    .ant-table-tbody tr td {
        overflow: hidden;
    }
}

.voyage-list {
    .ant-steps-item-description {
        font-size: 12px !important;
    }
}

.top-header {
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after {
        border: none !important;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
        border-bottom: 2px solid #000 !important;
    }
}
tfoot th,
tfoot td {
    background: #fafafa !important;
}

@root-entry-name: default;@primary-color: #ff6a1b;